import consumer from "./consumer"

$(function() {
  $('[data-channel-subscribe="conversation"]').each(function(index, element) {
    var $element = $(element);
    var conversation_id = $element.data('conversation-id');
    var messageTemplate = $('[data-role="message-template"]');

    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)

    // App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "ConversationChannel",
        conversation: conversation_id
      },
      {
        received: function(data) {
          var content = messageTemplate.children().clone(true, true);
          content.find('[data-role="avatar"]').attr("src", data['avatar']);
          content.find('[data-role="message-text"]').text(data['message']);
          $element.append(content);
          $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});
