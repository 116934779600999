require('jquery');
import Rails from 'rails-ujs';
Rails.start();
// import {} from 'jquery-ujs'
require('channels')
require('stylesheets/redesign');
require('timepicker');

global.$ = $;
global.jQuery = $;

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// JavaScript
// let webpackContext = require.context('../src', true, /\.js$/)
// for(let key of webpackContext.keys()) { webpackContext(key) }

$(document).ready(function() {
  $('.favorite-btn').click(function() {
    let fillColor = 'none';
    let strokeColor = 'currentColor';
    let btnText = 'Favorite';

    if ('Favorite' === $(this).find('span').text()) {
      fillColor = 'pink';
      strokeColor = 'pink';
      btnText = 'Favorited!';
    }

    $(this).find('svg').css({fill: fillColor});
    $(this).find('svg').css({stroke: strokeColor});
    $(this).find('span').fadeOut(function() {
        $(this).text(btnText)
    }).fadeIn();
  });
});

window.loginForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    accountLogin() {
      const formData = new FormData(document.forms[0]);

      fetch('/users/sign_in.json', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData
      }).then((res) => {
        if (res.status == 201) {
          this.alert_visible = true
          this.alert_success = true
          this.alert_message = 'You are signed in! One moment...'
          window.location.replace("/browse")
        } else {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Invalid email or password. Please try again.'
        }
      }).catch(() => {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Sorry, there was an error. Please try again.'
      }).finally(() => {
        const inputEmail = document.getElementById()
        const inputPassword = document.getElementById()

        inputEmail.value = '';
        inputPassword.value = '';
        inputEmail.focus();
      })
    }
  }
}

window.updateAvatar = function(upload) {
  const avatar = document.getElementById("account_avatar");

  avatar.src = URL.createObjectURL(upload.files[0]);
}

window.accountForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    updateAccount() {
      const formData = new FormData(document.forms[0]);

      fetch('/account', {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData
      }).then(() => {
          this.alert_visible = true
          this.alert_success = true
          this.alert_message = 'Account updated!'
      }).catch(() => {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Sorry, there was an error. Please try again.'
      })
    }
  }
}

window.passwordForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    updatePassword() {
      const data = {
        current_password: document.querySelector('input[name="current_password"]').value,
        password: document.querySelector('input[name="password"]').value,
        password_confirmation: document.querySelector('input[name="password_confirmation"]').value
      };

      fetch('/account', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then(() => {
        this.alert_visible = true
        this.alert_success = true
        this.alert_message = 'Password updated!'
      }).catch(() => {
        this.alert_visible = true
        this.alert_success = false
        this.alert_message = 'Sorry, something went wrong.'
      }).finally(() => {
        document.getElementById('password_form').reset()
      })
    }
  }
}

window.childInfoForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    updateChildInfo() {
      const data = {
        child_count: document.querySelector('input[name="child_count"]').value,
        special_needs: document.querySelector('input[name="special_needs"]').value,
      };

      fetch('/account', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then(() => {
          this.alert_visible = true
          this.alert_success = true
          this.alert_message = 'Child information updated!'
      }).catch(() => {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Sorry, something went wrong.'
      })
    }
  }
}

window.availabilityForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    updateAvailability() {
      const days = ['sunday', 'monday', 'tuesday',
        'wednesday', 'thursday', 'friday', 'saturday'];
      const data = {};
      for (const day of days) {
        const available_key = day + '_available';
        const available_query = '[name="' + available_key + '"]';
        data[available_key] = document.querySelector(available_query).checked;
        const start_key = day + '_start';
        const start_query = '[name="' + start_key + '"]';
        data[start_key] = document.querySelector(start_query).value;
        const end_key = day + '_end';
        const end_query = '[name="' + end_key + '"]';
        data[end_key] = document.querySelector(end_query).value;
      }

      const work_types = ['is_full_time', 'is_part_time', 'is_parent_nanny'];
      for (const work_type of work_types) {
        const work_type_query = '[name="' + work_type + '"]';
        data[work_type] = document.querySelector(work_type_query).checked;
      }

      fetch('/account', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then(() => {
          this.alert_visible = true
          this.alert_success = true
          this.alert_message = 'Availability updated!'
      }).catch(() => {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Sorry, something went wrong.'
      })
    }
  }
}

window.updateWorkHistoryForm = function(id) {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    deleted: false,
    show_confirmation: false,
    updateWorkHistory() {
      const scope = 'form[name="' + id + '"] ';
      const data = {
        id: id,
        title: document.querySelector(scope + '[name="title"]').value,
        kid_count: document.querySelector(scope + '[name="kid_count"]').value,
        beginning: document.querySelector(scope + '[name="beginning"]').value,
        ending: document.querySelector(scope + '[name="ending"]').value,
        description: document.querySelector(scope + '[name="description"]').value,
      };

      fetch('/work_histories', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then(() => {
        this.alert_visible = true
        this.alert_success = true
        this.alert_message = 'Work history updated!'
      }).catch(() => {
        this.alert_visible = true
        this.alert_success = false
        this.alert_message = 'Sorry, something went wrong.'
      })
    },
    deleteWorkHistory() {
      const data = { id: id };

      fetch('/work_histories/' + id, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        }
      }).then(() => {
        this.show_confirmation = false
        this.deleted = true
      }).catch(() => {
        this.show_confirmation = false
        this.alert_visible = true
        this.alert_success = false
        this.alert_message = 'Sorry, something went wrong.'
      })
    }

  }
}

window.newWorkHistoryForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    addWorkHistory() {
      const data = {
        title: document.querySelector('input[name="new_wh_title"]').value,
        beginning: document.querySelector('input[name="new_wh_beginning"]').value,
        ending: document.querySelector('input[name="new_wh_ending"]').value,
        kid_count: document.querySelector('input[name="new_wh_kid_count"]').value,
        description: document.querySelector('textarea[name="new_wh_description"]').value,
      };

      fetch('/work_histories', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then((response) => response.text())
      .then((html) => {
        var histories = document.getElementById('work_histories');
        histories.innerHTML = histories.innerHTML + html;

        document.getElementById('new_work_history_form').reset();

        this.alert_visible = true;
        this.alert_success = true;
        this.alert_message = 'Work history created!';
      }).catch(() => {
        this.alert_visible = true;
        this.alert_success = false;
        this.alert_message = 'Sorry, something went wrong.';
      })
    }
  }
}

window.privacyForm = function() {
  return {
    alert_visible: false,
    alert_success: '',
    alert_message: '',
    updatePrivacySettings() {
      const data = {
        hidden: document.querySelector('input[name="hidden"]').value
      };

      fetch('/account', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(data)
      }).then(() => {
          this.alert_visible = true
          this.alert_success = true
          this.alert_message = 'Privacy settings updated!'
      }).catch(() => {
          this.alert_visible = true
          this.alert_success = false
          this.alert_message = 'Sorry, something went wrong.'
      })
    }
  }
}
